<template>
    <div>
        <DataEcosystem />
    </div>
</template>


<script>
import DataEcosystem from "@/components/DataEcosystem.vue";

export default {
    components: {
        DataEcosystem,
    }
}
</script>
